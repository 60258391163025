import {Button, Card, CardActions, CardContent, CardMedia, Typography} from "@mui/material";
import {commonStyles} from "../../style/commonStyle";
import {useNavigate} from "react-router-dom";
import { API_CONFIGS } from "../../../config";

export const EventCard = (props) => {

    const classes = commonStyles()
    const {event} = props

    const navigate = useNavigate()
    const handleOnClick = (id) => {
        navigate(`/events/${id}`)
    }

    return(
        <Card data-aos="zoom-out-up" sx={{ width: 350, margin: '0px 10px 10px 0px'  }}
              onClick={() => handleOnClick(event.id)}
        >
            <CardMedia
                sx={{ height: 140 }}
                image={API_CONFIGS.fileBaseUrl + '' + event.thumbnail}
                title="green iguana"
            />
            <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                    {event.titleSinhala}
                </Typography>
                <Typography fontFamily={'Roboto'} gutterBottom variant="h7" component="div">
                    {event.createdDate}
                </Typography>
                <Typography className={classes.textTruncate} variant="body2" color="text.secondary">
                    {event.description}
                </Typography>
            </CardContent>
            <CardActions sx={{display: "flex", justifyContent: "center"}}>
                <Button className={classes.commonButton} size="medium" variant="outlined">;j ÿrg;a
                    lshõùug'''''</Button>
            </CardActions>
        </Card>
    )
}