import {useMediaQuery} from "@mui/material";
import {useParams} from "react-router-dom";
import {PageBodyContent} from "../../../ui/inngerPageContent/pageBodyContent";
import {useEffect, useState} from "react";
import {getEventById} from "../../../../client/events/call";
import {LoginWrapper} from "../../../ui/layout/loginWrapper";
import {FloatingCircle} from "../../../ui/animation/floatingCircle";
import * as React from "react";
import { PageLayout } from "../../../ui/layout/pageLayout";

export const Event = (props) => {
    const minWidth600 = useMediaQuery('(min-width:600px)');

    const params = useParams()
    const [event, setEvent] = useState(null);
    const [id, setId] = useState("");

    useEffect(() => setId(params.id), [params]);

    useEffect(() => {
        if (id) {
            getEventById((result) => {
                    setEvent(result.content)
                },
                id,
                error => {

                },)
        }
    }, [id])

    const handleOnLoginSuccess = () => {
        getEventById((result) => {
                setEvent(result.content)
            },
            id,
            error => {

            },)
    }

    return (
        <PageLayout header={event ? event.titleSinhala : ""} sx={{}}>
            <LoginWrapper
                callback={handleOnLoginSuccess}
            >
                <FloatingCircle/>
                {event && (
                    <PageBodyContent parentId={id}
                                     containers={event.webPageContents}
                                     parentType={"EVENT"}/>
                )}
            </LoginWrapper>
        </PageLayout>
    );
}