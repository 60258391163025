import {makeStyles} from "@mui/styles";

export const commonStyles = makeStyles({
    gradientContainer: {
        "& > *": {
            fontFamily: "FMDerana"
        },
        marginTop: '1rem',
        marginLeft: '5vw',
        marginBottom: '1rem',
        marginRight: '5vw',
        padding: '1rem',
        borderRadius: 10,
        boxShadow: `0px 2px 10px rgba(0, 62, 200, 0.5)`,
        backgroundImage: `conic-gradient(from 50deg at 50% 50%,
                    rgba(242, 245, 246, 0.08),
                    rgba(255, 97, 0, 0.08),
                    rgba(0, 255, 55, 0.08),
                    rgba(139, 150, 254, 0.08),
                    rgba(246, 47, 214, 0.08),
                    rgba(255, 133, 0, 0.08),
                    rgba(1, 246, 216, 0.08),
                    rgba(152, 250, 0, 0.08),
                    rgba(154, 0, 252, 0.08))`
    },
    commonGathaContainer: {
        margin: 5,
        padding: 20,
    },
    pageImageContainer: {
        width: '50%',
    },
    responsiveImage: {
        width: '100%',
        maxWidth: '500px',
        height: 'auto',
    },
    commonGathaText: {
        fontSize: '2rem',
        lineHeight: 1.5
    },
    commonButton: {
        fontFamily: "FMSamantha"
    },
    cta: {
        height: "50vh",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    },
    textTruncate: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    }
});