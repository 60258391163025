import { Box, Typography, useMediaQuery } from "@mui/material";
import { API_CONFIGS } from "../../../config";

export const CommonTextContainer = (props) => {

    const { font, text, flexDirection, fontSize, fontStyle, lineHeight,
        textAlign, letterSpacing, wordSpacing, color, backgroundColor,
        margin, padding, borderLeft, borderRight, borderBottom, borderTop, width,
        borderTopLeftRadius, borderTopRightRadius, borderBottomLeftRadius, borderBottomRightRadius, wrapImage } = props.data

    const matches = useMediaQuery('(min-width:992px)'); 

    return (
        <div data-aos={props.transition}>
            <Box sx={{ display: 'flex', flexDirection: `${matches ? "row" : "column"}${flexDirection}`, marginTop: '1rem' }}>
                <Typography
                    sx={{
                        fontSize: fontSize,
                        fontFamily: font,
                        fontStyle: fontStyle,
                        lineHeight: lineHeight,
                        textAlign: textAlign,
                        letterSpacing: letterSpacing,
                        wordSpacing: wordSpacing,
                        color: color,
                        backgroundColor: backgroundColor,
                        margin: margin,
                        padding: padding,
                        borderLeft: borderLeft,
                        borderRight: borderRight,
                        borderBottom: borderBottom,
                        borderTop: borderTop,
                        width: width,
                        borderTopLeftRadius: borderTopLeftRadius,
                        borderTopRightRadius: borderTopRightRadius,
                        borderBottomLeftRadius: borderBottomLeftRadius,
                        borderBottomRightRadius: borderBottomRightRadius,
                    }}
                >
                    {text}
                </Typography>
                {wrapImage && wrapImage.image && (
                    <>
                        <img
                            component="img"
                            style={{
                                marginLeft: '1rem',
                                width: wrapImage.size,
                                height: wrapImage.size,
                                shapeOutside: `${API_CONFIGS.fileBaseUrl}${wrapImage.image}`,
                                borderLeft: wrapImage.borderLeft,
                                borderRight: wrapImage.borderRight,
                                borderTop: wrapImage.borderTop,
                                borderBottom: wrapImage.borderBottom,
                                borderTopLeftRadius: wrapImage.borderTopLeftRadius,
                                borderTopRightRadius: wrapImage.borderTopRightRadius,
                                borderBottomLeftRadius: wrapImage.borderBottomLeftRadius,
                                borderBottomRightRadius: wrapImage.borderBottomRightRadius,
                            }}
                            src={`${API_CONFIGS.fileBaseUrl}${wrapImage.image}`}
                        />
                    </>
                )}
            </Box>
        </div>
    )
}