import {makeStyles} from "@mui/styles";
import {Box, Button} from "@mui/material";
import {ArrowDownward} from "@mui/icons-material";

export default function WallPaper(props) {
    const {} = props;
    const classes = makeStyles({
        bgImage: {
            /* Full height */
            height: "100vh",

            /* Center and scale the image nicely */
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: -1

        },
    });
    return (
        <Box className={classes.bgImage}
             style={{
                 backgroundImage: `url("/home/silhouetted-leaves-gracefully-cast-translucent-shadows-neutral-backdrop-kissed-by-morning-sunlight.jpg")`,
                 height: "100vh",
                 backgroundPosition: "center",
                 backgroundRepeat: "no-repeat",
                 backgroundSize: "cover",
                 position: "relative",
                 display: "flex",
                 justifyContent: "center",
                 alignItems: "center",
             }}
        >
            <Box
                sx={{
                    position: "absolute",
                    margin: "auto",
                    width: '60%',
                    height: '20%',
                    borderRadius: '1rem',
                    fontFamily: 'FMBasuru',
                }}
            >
                <p data-aos="fade-up"
                    style={{
                        fontSize: '2rem', fontFamily: "FMGangani",
                        color: '#164919'
                    }}
                >
                    {`yd;ami isidrd msßuÈk m%{dj hï m%idohla ika;dkh ;=< cks; lr;a o th `}
                    <span data-aos="fade-down" style={{
                        fontFamily: "FMSamantha",
                        fontSize: "4rem",
                        color: "#d73a06"
                    }}>
                        mdidÈl
                    </span> {`kï fõ'`}
                </p>
            </Box>
            <Button variant={"outlined"} size={'large'}
                    onClick={() => document.getElementById("introduction").scrollIntoView({
                        behavior: 'smooth',
                        inline: 'center'
                    })}
                    style={{
                        border: "3px solid" +
                            "#f5c991",
                        position: "absolute",
                        margin: "60vh auto auto auto",
                        width: '100px'
                    }}
            >
                <ArrowDownward style={{color: "#f5c991"}}/>
            </Button>
        </Box>
    );
};