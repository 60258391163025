import {Grid} from "@mui/material";
import {EventCard} from "./eventCard";
import {useEffect, useState} from "react";
import {loadEventSuggestions} from "../../../client/events/call";

export const MainSectionEvents = () => {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        loadEventSuggestions(result => {
                setEvents(result.content)
            },
            null,
            null,
        );
    }, [])

    return (
        <div style={{
            margin: '2rem 0 0 0'
        }}>
            {events && (
                <Grid container spacing={0}
                      sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          margin: '0 0 2rem 0'
                      }}
                >
                    {events.map((event) => (
                        <EventCard event={event}/>
                    ))}
                </Grid>
            )}
        </div>
    )
}