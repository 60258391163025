import * as React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';

export default function FloatingActionButtons(props) {
    return (
        <Box sx={{'& > :not(style)': {m: 1, mt: 0}}}>
            {props.config.map((item, index) => (
                <>
                    {item.visible ? (
                        <Fab sx={{textTransform: 'lowercase', fontFamily: 'FMEmanee', fontSize: '1.2rem'}} size={'large'} key={index} color="primary" variant="extended" aria-label="add"
                             onClick={() => item.onClickAction(item.modelName)}
                        >
                            {item.icon} {item.title}
                        </Fab>
                    ) : (
                        <></>
                    )}
                </>
            ))}
        </Box>
    );
}