import {useNavigate, useParams} from "react-router-dom";
import {Box, Collapse, Grid, useMediaQuery} from "@mui/material";
import {useEffect, useState} from "react";
import {CommonContainer} from "../../ui/container/commonContainer";
import {EventCard} from "./eventCard";
import {loadEventSuggestions} from "../../../client/events/call";
import Pagination from "../../ui/pagination/pagination";
import {Search} from "@mui/icons-material";
import EventSearchForm from "./eventSearchForm";
import FloatingActionButtons from "../../ui/buttons/floatingActions";
import { PageLayout } from "../../ui/layout/pageLayout";

export default function Events() {
    const minWidth600 = useMediaQuery('(min-width:600px)');

    const navigate = useNavigate()
    const {id} = useParams()
    const [events, setEvents] = useState([]);
    const [pagination, setPagination] = useState({})
    const {page, rowsPerPage} = pagination

    const [searchFormCollapse, setSearchFormCollapse] = useState(false)

    const [searchParams, setSearchParams] = useState({});
    const handleSearchFormCollapseClose = () => setSearchFormCollapse(false);
    const handleSearchFormCollapseOpen = () => setSearchFormCollapse(true);
    const getEventSuggestions = (page, rowsPerPage, params) => {
        loadEventSuggestions(result => {
                setEvents(result.content)
                setPagination(result.pagination);
            },
            error => {

            },
            page,
            rowsPerPage,
            null,
            params ? params : searchParams,
        );
    }

    useEffect(() => {
        getEventSuggestions()
    }, [])

    useEffect(getEventSuggestions, [page, rowsPerPage])

    const floatingActionConfig = [
        {
            title: "fidhkak''''",
            icon: <Search sx={{mr: 1}}/>,
            onClickAction: handleSearchFormCollapseOpen,
            visible: !searchFormCollapse
        }
    ]

    return (
        <PageLayout header={"jHdmD;s iy jevigyka"} sx={{}}>
            <Collapse in={searchFormCollapse} timeout="auto" unmountOnExit>
                <CommonContainer>
                    <EventSearchForm
                        searchPayments={(params) => {
                            setSearchParams(params)
                            getEventSuggestions(null, null, params)
                        }}
                        closeForm={handleSearchFormCollapseClose}
                    />
                </CommonContainer>
            </Collapse>
            <FloatingActionButtons sx={{fontsize: '2rem'}} config={floatingActionConfig}/>
            {events && (
                <CommonContainer transitionEnabled={false}>
                    <Grid container spacing={0}
                          sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              margin: 0
                          }}
                    >
                        {events.map((event, index) => (
                            <EventCard event={event}/>
                        ))}
                    </Grid>
                </CommonContainer>
            )}
            <Pagination
                reloadData={(pageNumber, pageSize) => getEventSuggestions(pageNumber, pageSize)}
                pagination={pagination}
            />
            <Box mb={2}>

            </Box>
        </PageLayout>
    );
}