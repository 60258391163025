import FullScreenDialog from "../modal/fullScreenDialog";
import {Fragment, useContext, useState} from "react";
import {AppContext} from "../../../context/appContext";

export const LoginWrapper = (props) => {
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

    const [loginDialogOpen, setLoginDialogOpen] = useState(true);

    const {auth} = useContext(AppContext)

    const handleOnLoginSuccess = () => {
        setIsUserLoggedIn(true)
        setLoginDialogOpen(false);
        props.callback()
    };
    return (
        <Fragment>
            {auth && auth.user_name && (
                <>
                    {props.children}
                </>
            )}
            {!auth || !auth.user_name && (
                <FullScreenDialog
                    onSuccess={handleOnLoginSuccess}
                    open={loginDialogOpen}
                >

                </FullScreenDialog>
            )}
        </Fragment>
    )
}