import {Box, Grid, Typography} from "@mui/material";
import {Email, LocationCity, MyLocation, Phone, Place} from "@mui/icons-material";


export const Contacts = (props) => {

    const contactItemContainerStyle = {
        width: '25vw',
        backgroundColor: "#0001",
        margin: '2vh 1vw 2vh 1vw',
        padding: '2vh 1vw 2vh 1vw',
        borderRadius: .3,
        minWidth: '250px',
        overflow: 'hidden',
    }

    const mapContainterStyle = {
        margin: "2vw"
    }

    const iconContainer = {
        border: "3px dashed green",
        width: '5rem',
        height: '5rem',
        borderRadius: '50vh',
        margin: '2vh auto 2vh auto',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    }

    const iconStyle = {
        margin: 'auto',
        color: 'green',
    }

    const lableStyle = {
        color: 'grey',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        fontFamily: 'FMEmanee',
    }

    const valueStyle = {
        fontSize: '1.1rem',
        fontFamily: 'FMEmanee',
    }
    return (
        <Box>
            <p data-aos="fade-up"
               style={{
                   fontSize: '3rem',
                   color: '#6f8303',
                   fontFamily: 'FMBasuru',
                   margin: 0,
               }}
            >úuiSï</p>
            <Grid>
                <Grid item sx={{
                    display: "flex", gap: "1rem",
                    justifyContent: "center",
                }}>
                    <div data-aos="fade-left" style={contactItemContainerStyle}>
                        <Grid item sx={iconContainer}>
                            <Place fontSize={'large'} sx={iconStyle}/>
                        </Grid>
                        <Grid item>
                            <Typography sx={lableStyle}>,smskh</Typography>
                            <Typography sx={valueStyle}>mdidÈl fikiqk" Y%S ir udj; j,a.u" ud;r</Typography>
                        </Grid>
                    </div>
                    <div data-aos="fade-in" style={contactItemContainerStyle}>
                        <Grid item sx={iconContainer}>
                            <Email fontSize={'large'} sx={iconStyle}/>
                        </Grid>
                        <Grid item>
                            <Typography sx={lableStyle}>B fï,a</Typography>
                            <Typography style={{fontFamily: "Roboto"}}
                                        sx={valueStyle}>pasadikasenasuna@gmail.com</Typography>
                        </Grid>
                    </div>
                    <div data-aos="fade-right" style={contactItemContainerStyle}>
                        <Grid item fontSize={'large'} sx={iconContainer}>
                            <Phone sx={iconStyle}/>
                        </Grid>
                        <Grid item>
                            <Typography sx={lableStyle}>ÿrl:k</Typography>
                            <Typography sx={valueStyle}>041-2238221</Typography>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
            <Box sx={mapContainterStyle}>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d19429.89546265374!2d80.51296381888035!3d5.946285567030781!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae13f63132a79c1%3A0x8071a5c185a5c89b!2sSenasuna!5e0!3m2!1sen!2slk!4v1636351646244!5m2!1sen!2slk"
                    width="100%" height="450" style={{border: "0"}} allowFullScreen="" loading="lazy"></iframe>
            </Box>
        </Box>
    )
}