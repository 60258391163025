import * as React from "react";
import { Box, Typography } from "@mui/material";

function getRandomColor() {
    return "hsl(" + Math.random() * 360 + ", 60%, 90%)";
}

export const PageLayout = (props) => {
    

    
    const [backgroundColor, setBackgroundColor] = React.useState(getRandomColor())

    const headerStyle = {
        align: 'center',
        alignJustify: 'center',
        textAlign: 'center',
        display: 'flex',
        marginTop: '90px',
        color: '#000000',
        fontFamily: 'FMBasuru',
        fontSize: '3.5rem',
        justifyContent: "center",
    }

    return (
        <Box sx={{ margin: 0, width: '100%', overflow: 'hidden', backgroundColor: 'rgb(255,255,255)' }}>
            <Box sx={{
                margin: 0,
                width: '65%',
                overflow: 'hidden',
                backgroundColor: backgroundColor,
                align: 'center',
                padding: '20px',
                alignJustify: 'center',
                textAlign: 'center',
                justifyContent: "center",
                margin: 'auto',
                borderBottomRightRadius: '20px',
                borderBottomLeftRadius: '20px',
            }}>
                {/* {props.header && (<Typography sx={headerStyle}>
                    {props.header}
                </Typography>)} */}
            </Box>

            <Box sx={{ width: '100%', overflow: 'hidden', margin: 0 }}>
                {props.children}
            </Box>
        </Box>
    )
}
