import {API_CONFIGS} from "../../config";
import {BFF_ENDPOINTS} from "../../utils/constants";
import {_get} from "../fetch-api";

export const searchMonasteries = (
    callback,
    errorCallback,
    pageNumber,
    pageSize,
    status,
    keyword
) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.searchMonasteries}`;
  const params = {
    pageNumber,
    pageSize,
    status,
    keyword,
    sortProperty: 'name',
  };
  _get(callback, endpointURL, params, null, errorCallback);
};

export const loadMonasteryById = (callback, errorCallback, id) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.loadMonasteryById}/${id}`;
  _get(callback, endpointURL, {}, null, errorCallback);
};

export const loadMonasteriesSuggestions = callback => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.loadMonasterySuggestions}`;
  _get(callback, endpointURL);
};
