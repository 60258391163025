import { Box, Divider } from "@mui/material";
import { commonStyles } from "../../style/commonStyle";
import { CommonTextContainer } from "./commonTextContainer";
import { CommonImagesContainer } from "./CommonImagesContainer";
import { CommonTextListContainer } from "./CommonTextListContainer";
import { useEffect, useState } from "react";
import { getContentWrapContainerById } from "../../../client/contentWrapContainers/call";


export const PageContentWrapContainer = (props) => {
    const classes = commonStyles()

    const [id, setId] = useState(props.containerId);
    const [contents, setContents] = useState([])
    const [settings, setSettings] = useState({});

    useEffect(() => {
        if (id) {
            getContentWrapContainerById(
                result => {
                    setContents(result.content.contents)
                    setSettings(result.content.settings)
                },
                error => {
                },
                id,
            );
        }
    }, [])
    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: settings ? settings.position : "center",
                }}
            >
                <Box sx={{
                    width: settings.width,
                    height: settings.height,
                    margin: settings.margin,
                    padding: settings.padding,
                    border: settings.border,
                    borderRadius: settings.borderRadius,
                    boxShadow: settings.boxShadow,
                    position: "relative",
                    backgroundImage: settings.backgroundImage,
                    backgroundColor: settings.backgroundColor,
                    overflow: "hidden",
                }}
                >
                    <Box
                        sx={{
                            display: 'flow-root',
                            padding: '1rem',
                            zIndex: 3,
                        }}
                    >
                        {contents && contents.map((item, index) => (
                            <Box key={index}>
                                {("TEXT" === item.type && (
                                    <CommonTextContainer
                                        data={item}
                                        transition={settings.transition}
                                    />
                                ))}
                                {("IMAGES" === item.type && (
                                    <CommonImagesContainer
                                        containerId={props.containerId}
                                        data={item}
                                        transition={settings.transition}
                                    />
                                ))}
                                {("TEXT_LIST" === item.type && (
                                    <CommonTextListContainer
                                        data={item}
                                        transition={settings.transition}
                                    />
                                ))}

                            </Box>
                        ))}
{/*                         
                        <Divider sx={{
                            marginTop: '80px',
                            borderBottom: "8px solid #ebebeb",
                            borderBottomLeftRadius: "5px",
                            borderBottomRightRadius: "5px",
                            borderTopLeftRadius: "5px",
                            borderTopRightRadius: "5px"
                        }} /> */}
                    </Box>
                </Box>
            </Box>
        </div>
    )
}