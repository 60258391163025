import {AppBar, Box, IconButton, Toolbar,} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import * as React from "react";
import {useEffect, useState, useMemo, forwardRef, useContext,} from "react";
import {StaticRouter} from "react-router-dom/server";
import {Link as RouterLink, MemoryRouter, useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LoginMenu from "./loginMenu";
import {AppContext} from "../../../context/appContext";

export default function MainAppBar(props) {

    const navigate = useNavigate()
    const {handleDrawerToggle} = props

    const {monasterySuggestions, foundationSuggestions} = useContext(AppContext)

    const [wildMonasteries, setWildMonasteries] = useState([])
    const [monasteries, setMonasteries] = useState([])
    const [hospitalityMonasteries, setHospitalityMonasteries] = useState([])
    const [shasanikaServicesFoundations, setShasanikaServicesFoundations] = useState([])
    const [socialServicesFoundations, setSocialServicesFoundations] = useState([])

    useEffect(() => {
        let wildMon = [];
        let mon = [];
        let hosMon = [];
        monasterySuggestions.forEach(monastery => {
            if (monastery.type === "WMO") {
                wildMon.push(monastery);
            } else if (monastery.type === "MON") {
                mon.push(monastery);
            } else if (monastery.type === "HOM") {
                hosMon.push(monastery);
            }
        });
        setWildMonasteries(wildMon)
        setMonasteries(mon)
        setHospitalityMonasteries(hosMon)
    }, [
        monasterySuggestions
    ])

    useEffect(() => {
        let shaFound = [];
        let socialFound = [];
        foundationSuggestions.forEach(foundation => {
            if (foundation.type === "SHS") {
                shaFound.push(foundation);
            } else if (foundation.type === "SOS") {
                socialFound.push(foundation);
            }
        });
        setShasanikaServicesFoundations(shaFound)
        setSocialServicesFoundations(socialFound)
    }, [
        foundationSuggestions
    ])

    function Router(props) {
        const {children} = props;
        if (typeof window === 'undefined') {
            return <StaticRouter location="/home">{children}</StaticRouter>;
        }

        return (
            <MemoryRouter initialEntries={['/home']} initialIndex={0}>
                {children}
            </MemoryRouter>
        );
    }

    Router.propTypes = {
        children: PropTypes.node,
    };

    function ListItemLink(props) {
        const {icon, primary, to, level} = props;

        const renderLink = useMemo(
            () =>
                forwardRef(function Link(itemProps, ref) {
                    return <RouterLink to={to} ref={ref} {...itemProps} role={undefined}/>;
                }),
            [to],
        );

        return (
            <li style={{padding: 0, margin: 0}}>
                <ListItemButton
                    selected={false}
                    onClick={(event) => {
                    }}
                    component={renderLink}
                >
                    {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                    <ListItemText primary={primary}/>
                </ListItemButton>
            </li>
        );
    }

    ListItemLink.propTypes = {
        icon: PropTypes.element,
        primary: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
    };

    const handleLogout = () => {
        props.handleLogout()
    }
    const handleLogoClick = () => {
        navigate(`/home`);
    }

    return (
        <AppBar component="nav">
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{mr: 2, display: {sm: 'none'}}}
                >
                    <MenuIcon/>
                </IconButton>
                <p data-aos="fade-left"
                   onClick={handleLogoClick}
                   style={{
                       flexGrow: 1, display: {xs: 'none', sm: 'block'},
                       fontSize: '2.5rem', cursor: "pointer",
                       padding: 0,
                       margin: 0,
                       fontFamily: "FMSamantha"
                   }}
                >
                    mdidÈl
                </p>
                <Box sx={{display: {xs: 'none', sm: 'block',}}}>
                    <Box className="fm-samantha nav-menu d-none d-lg-block">
                        <ul data-aos="fade-down">
                            <li className="drop-down"><a style={{margin: 0, padding: 0}} href={"#"}>mdidÈl
                                fikiqka</a>
                                <ul>
                                    {monasteries != null && (monasteries.map(monastery => (
                                        <ListItemLink to={`/monasteries/${monastery.id}`} primary={monastery.name}/>
                                    )))}
                                    <li className="drop-down"><a href={"#wana-senasun"}>mdidÈl jk fikiqka</a>
                                        <ul>
                                            {wildMonasteries != null && (wildMonasteries.map(monastery => (
                                                <ListItemLink to={`/monasteries/${monastery.id}`}
                                                              primary={monastery.name}/>
                                            )))}
                                        </ul>
                                    </li>
                                    <li className="drop-down"><a href={"#"}>mdidÈl i;aldrl fikiqka</a>
                                        <ul>
                                            {hospitalityMonasteries != null && (hospitalityMonasteries.map(monastery => (
                                                <ListItemLink to={`/monasteries/${monastery.id}`}
                                                              primary={monastery.name}/>
                                            )))}
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <ListItemLink to={`/deshana`} primary={`mdidÈl O¾u foaYKd`}/>
                            <ListItemLink to={`/events`} primary={`jHdmD;s iy jevigyka`}/>
                            <li className="drop-down"><a href={"#"}>mdidÈl mokï</a>
                                <ul>
                                    <li className="drop-down"><a href="#">Ydiksl fiajd mokï</a>
                                        <ul>
                                            {shasanikaServicesFoundations != null && (shasanikaServicesFoundations.map(foundation => (
                                                <ListItemLink to={`/foundations/${foundation.id}`}
                                                              primary={foundation.name}/>
                                            )))}
                                        </ul>
                                    </li>
                                    <li className="drop-down"><a href="#">iudc i;aldrl mokï</a>
                                        <ul>
                                            {socialServicesFoundations != null && (socialServicesFoundations.map(foundation => (
                                                <ListItemLink to={`/foundations/${foundation.id}`}
                                                              primary={foundation.name}/>
                                            )))}
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <ListItemLink to={`/contact`} primary={`úuiSï`}/>
                        </ul>
                    </Box>
                </Box>
                <div data-aos="fade-right">
                    <LoginMenu
                        onClickLogoutButton={handleLogout}
                    />
                </div>
            </Toolbar>
        </AppBar>
    )
}