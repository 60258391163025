import {Box} from "@mui/material";

export const FooterGradient = (props) => {

    return (
        <Box m="auto">
            <div style={{
                insetInlineEnd: 0,
                marginLeft: '3%',
                insetBlockStart: '53%',
                overflow: 'hidden',
                maxInlineSize: '1800px',
                blockSize: '98%',
                zIndex: 10,
                insetInlineStart: `80%`,
                minWidth: '100%',
                background: `linear-gradient(49.11deg, rgb(255 255 255) -3.07%, rgb(255 222 243) 32.89%, rgb(255 185 134) 71.58%, rgb(255 116 47) 101.03%)`,
                '-webkit-clip-path': `path("m-.00006072 983h1792.47006072v-376.386c0-35.415-8.93-70.258-25.97-101.304l-175.37-319.472c-25.46-46.379-72.46-76.925-125.18-81.361l-1156.528-97.29484c-53.248-4.47955-105.148 18.27644-137.929 60.47564l-149.3583 192.2692c-14.34681 18.469-22.13476072 41.189-22.13476072 64.576z")`,
                'clip-path': `path("m-.00006072 983h1792.47006072v-376.386c0-35.415-8.93-70.258-25.97-101.304l-175.37-319.472c-25.46-46.379-72.46-76.925-125.18-81.361l-1156.528-97.29484c-53.248-4.47955-105.148 18.27644-137.929 60.47564l-149.3583 192.2692c-14.34681 18.469-22.13476072 41.189-22.13476072 64.576z")`,
            }}>

                Hello world
            </div>
        </Box>
    )
}