import {Box} from "@mui/material";

export const HeaderGradient = (props) => {

    return (
        <Box
            sx={{
                position: "absolute",
                top: "10%",
                right: "-15%",
                minWidth: '90%',
                opacity: '.1'
            }}
        >
            <div style={{
                insetInlineStart: `80%`,
                maxInlineSize: `1149px`,
                blockSize: `1143px`,
                background: `#ffe8d4`,
                backgroundImage: `radial-gradient(circle at 10% 30%,#7a1bdd, rgba(122,27,221,0) 55%),radial-gradient(circle at 40% 70%,#5690e8 10%,rgba(71,152,223,0) 50%),radial-gradient(circle at 100% 50%,rgba(255,140,50,.75),rgba(255,140,50,0) 60%),radial-gradient(circle at 50% 20%,#c936a1,rgba(201,54,161,0))`,
                backgroundSize: `1149px 1143px`,
                transform: `translate(350px,-230px)`,
                '-webkit-clip-path': `path("M0 0H1700L1615.64 318.382C1600.12 376.969 1550.86 420.458 1490.8 428.602L412.419 574.839C349.807 583.33 286.301 567.797 234.677 531.365L42.3408 395.631C15.7903 376.893 0 346.424 0 313.927V0Z")`,
                'clip-path': `M0 0H1700L1615.64 318.382C1600.12 376.969 1550.86 420.458 1490.8 428.602L412.419 574.839C349.807 583.33 286.301 567.797 234.677 531.365L42.3408 395.631C15.7903 376.893 0 346.424 0 313.927V0Z`

            }}>
            </div>
        </Box>
    )
}