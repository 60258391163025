import {Box} from "@mui/material";
import {commonStyles} from "../../style/commonStyle";

export default function CommonGathaContainer(props) {

    const classes = commonStyles()

    return (
        <Box data-aos="fade-up"
             sx={{
                 margin: '2rem',
                 padding: '2rem',
             }}
        >
            {props.children}
        </Box>
    )
}